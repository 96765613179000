import { styled, Typography } from '@mui/material';
import BaseHero from '../../components/BaseHero';

const AbooutHeroTitle = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: 800,
    color: "#F27405",
}));
const AbooutHeroSubTitle = styled(Typography)(() => ({
    width: "50%",
    fontSize: "36px",
    fontWeight: 800,
    textAlign: "center",
    marginTop: "20px"
}));

function AboutHero() {
    return (
        <BaseHero>
            <AbooutHeroTitle>A PROPOS DE NOUS</AbooutHeroTitle>
            <AbooutHeroSubTitle variant="h3">Nous nous engageons à construire votre entreprise en douceur</AbooutHeroSubTitle>
        </BaseHero>
    );
}

export default AboutHero;