import { styled, Typography } from "@mui/material";
import BaseHero from "../../components/BaseHero";

const PartenairesHeroTitle = styled(Typography)(() => ({
    fontSize: "16px",
    fontWeight: 800,
    color: "#F27405",
}));
const PartenairesHeroSubTitle = styled(Typography)(() => ({
    width: "50%",
    fontSize: "36px",
    fontWeight: 800,
    textAlign: "center",
    marginTop: "20px"
}));

function PartenairesHero() {
    return (
        <BaseHero>
            <PartenairesHeroTitle>NOS PARTENAIRES</PartenairesHeroTitle>
            <PartenairesHeroSubTitle variant="h3">Nous collaborons parfaitement avec nos Partenaires et le résultat est au top</PartenairesHeroSubTitle>
        </BaseHero>
    );
}

export default PartenairesHero;