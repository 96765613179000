import { styled } from '@mui/material';
import BlogCarouselItem from '../../components/BlogCarouselItem';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SectionStyle from '../../styles/SectionStyle';
import { RootState, useAppSelector } from '../../redux/store';

import 'swiper/css';
import 'swiper/css/pagination';
import dateConverter from '../../common/dateConverter';


const BlogPageCarouselContainer=styled(SectionStyle)(()=>({
    width: "100%",
    height: "85vh",
    padding: "0px",
}));

function BlogPageCarousel() {
    const articles = useAppSelector((state: RootState) => state.articles.articles);

    return (
        <BlogPageCarouselContainer>
            <Swiper modules={[Autoplay, Pagination]} slidesPerView={1} navigation autoplay={{delay: 4000}} pagination={{ clickable: true}}>
                {
                    articles.map((oneContent,index)=> <SwiperSlide key={index} style={{height: "85vh", backgroundColor: "blue"}}><BlogCarouselItem id={oneContent.id} cover={oneContent.cover} category={oneContent.category} date={dateConverter({timestamp: oneContent.date, action: "toDateString"})!.toString()} title={oneContent.title}/></SwiperSlide>)
                }
            </Swiper>
        </BlogPageCarouselContainer>
    );
}

export default BlogPageCarousel;