import styled from "@emotion/styled";
import { SxProps } from "@mui/material";

const PartenersImageContainerImage = styled("img")(({theme}) => ({
    padding: "10px",
    height: "60px",
    marginLeft: "16.8vw",
    marginBottom: "20px",
    transition:" transform .2s",
    "&:hover": {
        transform: "scale(1.5)",
    },
    
}));



interface Partenaires {
    icon: string,
    sx?: SxProps
}

function OurPartnersLogo({icon, sx = {}} : Partenaires) {
    return (
            <PartenersImageContainerImage src={icon} />
    );
}

export default OurPartnersLogo;