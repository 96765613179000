import { Container, Grid, styled, Typography } from "@mui/material";
import OurResultsImages from "../../components/OurResultsImages";

const OurResultsContainer = styled("div")(()=>({
    minHeight:"500px",
    display:"flex",
    alignItems:"center"
}))

function OurResults() {
    return (
        <OurResultsContainer>
            <Container>
                <Grid container>
                    <Grid item xs sx={{display:"flex",flexDirection:"column", justifyContent:"space-around", height:"350px",margin:"25px"}}>
                        <Typography sx={{ fontWeight:"bold"}}>A Propos</Typography>
                        <Typography variant="h6" sx={{color:"#FF8000", fontWeight:"bold"}}>DES RÉSULTATS CONCRETS</Typography>
                        <Typography>Agence web, nous accompagnons nos clients dans la création de site internet: étude, conception, design, développement, SEO. Notre agence web est capable de répondre à tous vos besoins et d’élaborer une véritable stratégie digitale. L’ergonomie d’un site Internet sert à le rendre facile à comprendre et à utiliser. L’UX Design couvre à la fois l’ergonomie, l’étude du comportement, l’ethonologie, le contenu et la navigation.</Typography>
                    </Grid>
                    <Grid item xs>
                        <OurResultsImages image={"images/ResultsImg.png"} /> 
                    </Grid>
                </Grid>
            </Container>
        </OurResultsContainer>
    );
}

export default OurResults;