import { Container, Grid } from '@mui/material';
import { clients } from '../common/data';
import OurClientsImages from './OurClientsImages';

function OurClientsList() {

    return (
        <Container>
            <Grid container>
                {
                    clients.map((client, index)=><Grid key={index} item xs display="flex" justifyContent="center"><OurClientsImages  img={client.img}/></Grid>)
                }
            </Grid>
        </Container>
    );
}

export default OurClientsList;