import { useEffect, useState } from 'react';
import PagesLoader from '../../components/PageLoader';
import Page from '../../components/Pages';
import { getArticles } from '../../redux/slices/articles';
import { RootState, useAppDispatch, useAppSelector } from '../../redux/store';
import BlogPageCards from '../../sections/BlogPage/BlogPageCards';
import BlogPageCarousel from '../../sections/BlogPage/BlogPageCarousel';

function BlogPage() {
    const [isLoading, setIsLoading] = useState(true);
    const articles = useAppSelector((state: RootState) => state.articles.articles);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if(articles.length) {
            setIsLoading(false);
        }else {
            dispatch(getArticles()).unwrap().then(() => {
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Page title="Blog">
            <PagesLoader isLoading={isLoading}>
                <BlogPageCarousel/>
                <BlogPageCards/>
            </PagesLoader>
        </Page>
    );
}

export default BlogPage;