import { styled, Typography } from "@mui/material";
import { services, ServicesDataDetails } from "../common/data";
import SectionStyle from "../styles/SectionStyle";
import ServiceDetails from "./ServiceDetails";

const ServicesDetailsContainer = styled(SectionStyle)(()=>({
    marginTop: '69px',
    marginBottom: '96px',
}));
const ServiceDetailsTitle = styled(Typography)(() => ({
    fontSize: "30px",
    fontWeight: 800,
    textAlign: "center",
    marginBottom: "20px"
}));

function ServicesDetails() {
    return (
        <ServicesDetailsContainer id="DetailSection">
            <ServiceDetailsTitle>
                Nos services
            </ServiceDetailsTitle>
            {
                ServicesDataDetails.map((service, index) => <ServiceDetails key={index} service={services[index]} descs={service.descs} img={service.img} title={service.title} subTitle={service.subTitle} sx={{backgroundColor: index % 2 === 0 ? "white" : "rgba(217, 217, 217, 0.12)" }}/>)
            }
        </ServicesDetailsContainer>
    );
}

export default ServicesDetails;