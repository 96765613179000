import { Button, styled, Typography } from '@mui/material';

const ContactOverViewButtonContainer = styled(Button)(()=>({
    display:"flex",
    justifyContent:"space-around",
    margin:"10px",
    height:"50px", 
    border:"2px solid #000000",
    textTransform: "lowercase",
    overflow: "hidden"
}));
const ContactOverViewButtonIcon = styled("img")(()=>({
    position:"relative",
    marginRight:"10px"
}));

interface Props {
    icone: string,
    text: string,
    link: string,
};

function ContactOverViewOneButton({icone, text, link}: Props) {
    return (
        <ContactOverViewButtonContainer variant='outlined' href={link}>
                <ContactOverViewButtonIcon src={icone}/>
                <Typography sx={{color:"black", fontWeight:"bold"}}>{text}</Typography>
        </ContactOverViewButtonContainer>
    );
}

export default ContactOverViewOneButton;