import { Box, styled, Typography } from "@mui/material";
import { ClientMenuItems } from "../../common/MenuConfig";
import FooterStyle from "../../styles/FooterStyle";
import FooterItems from "./FooterItems";

const FooterContainer = styled(FooterStyle)(() => ({
    minHeight: "250px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "black",
}));
const FreeMarketText = styled("div")(() => ({
    fontFamily: "InterBold,sans-serif !important",
    textAlign: "center",
    color: "white",
    fontSize: "20px"
}));
const FreeMarketLocation = styled(Typography)(() => ({
    fontFamily: "InterRegular,sans-serif !important",
    textAlign: "center",
    color: "white",
}));

function Footer() {
    return (
        <FooterContainer>
            <Box sx={{ display: "flex", justifyContent: "space-around"}}>
                {ClientMenuItems.map((menu, index) => <FooterItems key={index} title={menu.title} path={menu.path}/>)}
            </Box>
            <FreeMarketText>
                FREE MARKET GROUP
                <FreeMarketLocation>Agoè Minamadou, a côté de l'école GALICHA</FreeMarketLocation>
            </FreeMarketText>
        </FooterContainer>
    );
}

export default Footer;