import { styled, Typography } from "@mui/material";
import HomeSatisfaction from "../../components/HomeComponents/HomeSatisfaction";
import SectionStyle from "../../styles/SectionStyle";

const HomeHeroContainer = styled(SectionStyle)(({theme}) => ({
    minHeight: "90vh",
    display: "flex",
    alignItems: "flex-end",
    backgroundColor: "#000000",
    [theme.breakpoints.down("lg")]: {
        paddingTop: "200px",
        flexDirection: "column-reverse",
    }
}));
const HomeHeroElement = styled("div")(() => ({
    minHeight: "calc(100% - 250px)",
}));
const HomeHeroLeftElement = styled(HomeHeroElement)(({theme}) => ({
    width: "70%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "50px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        padding: "5px",
    }
}));
const HomeHeroSubTitle = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "14.7px",
    color: "#FF8D05",
}));
const HomeHeroTitle = styled(Typography)(({theme}) => ({
    maxWidth: "80%",
    fontFamily: "InterBold,sans-serif !important",
    fontWeight: 700,
    fontSize: "54px",
    color: "white",
    [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
        fontSize: "34px",
    }
}));
const HomeHeroDesc = styled(Typography)(({theme}) => ({
    maxWidth: "65%",
    marginTop: "100px",
    fontWeight: 400,
    fontSize: "20px",
    color: "white",
    textAlign: "justify",
    [theme.breakpoints.down("lg")]: {
        maxWidth: "100%",
        marginTop: "20px",
    }
}));
const HomeHeroRightElement = styled(HomeHeroElement)(({theme}) => ({
    width: "30%",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
        width: "100%"
    }
}));
const HomeHeroImage = styled("img")(({theme})=>({
    position: "absolute",
    right: "30px",
    bottom: "-60px",
    width: "160%",
    borderRadius: "20px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    objectFit: "cover",
    [theme.breakpoints.down("lg")]: {
        display: "none"
    }
}));

function HomeHero() {

    return (
        <HomeHeroContainer>
            <HomeHeroLeftElement>
                <HomeHeroSubTitle variant="body1">AGENCE DE SOLUTIONS COMMERCIALES ET MARKETING</HomeHeroSubTitle>
                <HomeHeroTitle variant="h2">{"Donnez à votre activité, l’équipe commerciale qu'il lui faut"}</HomeHeroTitle>
                <HomeHeroDesc variant="h5">
                    Au Free Market Group, nous comprenons mieux que quiconque, l'importance de gérer efficacement votre cellule commerciale et marketing.
                    <br/>
                    <br/>
                    Notre équipe de professionnels fournit des solutions commerciales et marketing sur mesure, dans le but d’accompagner votre entreprise, votre produit ou votre service tout au long de son processus de développement commercial.
                </HomeHeroDesc>
            </HomeHeroLeftElement>
            <HomeHeroRightElement>
                <HomeSatisfaction sx={{position: "absolute", right: "-10px", top: "-100px"}}/>
                <HomeHeroImage src="/images/home hero 1.jpeg"/>
            </HomeHeroRightElement>
        </HomeHeroContainer>
    );
}

export default HomeHero;