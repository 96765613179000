import { styled } from '@mui/material';
import OurClientsList from '../../components/OurClientsList';

const OurClientsOverViewContainer= styled("div")(()=>({

}))

function OurClientsOverView() {
    return (
        <OurClientsOverViewContainer>
            <OurClientsList/>
        </OurClientsOverViewContainer>
    );
}

export default OurClientsOverView