import * as firestore from "../firebase";
import { ArticleModel } from "../../../models/ArticleModel";

const articlesRef = firestore.collection(firestore.firestore, "articles");

export const getArticles = async (): Promise<Array<ArticleModel>> => {
    return (await firestore.getDocs(articlesRef)).docs.map(doc => {
        let article = doc.data() as ArticleModel;
        article.id = doc.id;
        return article;
    });
};
