import { ServiceModel } from "../models/ServiceModel";

export const services: Array<ServiceModel> = [
    {
        title: "ASSISTANCE COMMERCIALE",
        img: "images/A.png",
    },
    {
        title: "MANAGEMENT DE DEPARTEMENT",
        img: "images/M.png",
    },
    {
        title: "Gestion de stock",
        img: "images/G.png",
    }
];

export const ServicesDataDetails = [
    {
        title: "ASSISTANCE COMMERCIALE",
        subTitle: "NOUS SOMMES LA SOLUTION COMMERCIALE",
        descs: [
            `
            Il n’est pas évident, pour toutes les entreprises commerciales de disposer d’une ressource humaine complète, de la logistique adaptée et du temps nécessaire au développement intégral de son produit ou service.
Par conséquent, certaines actions commerciales, parce que ne pouvant pas être conduites efficacement en interne sont reléguées au second plan ou simplement ignorées. Ce qui constitue un manque à gagner significatif.
Fort de la qualité de son équipe commerciale, de son carnet d’adresse et de sa logistique, Free Market Group ; vous propose une Assistance Commerciale et Marketing de qualité, aussi bien dans le digital, que dans le physique.
            `,
            `A vos côtés ; de façon occasionnelle ou ponctuelle, nous assurons :`,
            "Les études de faisabilité commerciale.",
            "La planification stratégique.",
            "Le déploiement opérationnel.",
            "Le positionnement / produits.",
            "La distribution et la vente.",
            "Marketing digital.",
            "Le suivi et évaluation.",
        ],
        img: "images/A.png",
    },
    {
        title: "GESTION DU DEPARTEMENT",
        subTitle: "NOUS SOMMES VOTRE ÉQUIPE COMMERCIALE",
        descs: [
            `
            Tout responsable d’entreprise souhaiterait avoir à sa disposition, une vraie équipe de professionnels en charge du développement commercial de son produit ou de son service.
Il s’agit d’un idéal dont les contours, en termes de ressources humaines, logistiques et surtout financiers ne sont pas toujours à la portée de toutes les entreprises.
Free Market Group, propose de piloter votre Département Commercial et Marketing, en mettant, immédiatement au service de votre activité, sa logistique et son équipe de professionnels qualifiés.
Le modèle de gestion commerciale que nous proposons, permet de minimiser les charges, tout en garantissant à votre entreprise une cellule commerciale de qualité.
            `,
            `En notre qualité d’équipe en charge de votre développement commercial, nous assurons :`,
            "Les planifications stratégiques.",
            "Leur mise en œuvre opérationnelle.",
            "Le suivi et évaluation continue.",
        ],
        img: "images/M.png",
     },
    {
        title: "GESTION DE STOCK",
        subTitle: "A VOS CÔTÉS POUR UNE GESTION OPTIMALE",
        descs: [
            `
            La gestion commerciale complète et efficace de votre activité, passe par une parfaite maitrise des flux de votre stock de produits.
L’activité des ventes étant directement liée à la maitrise de la gestion des stocks de marchandises et articles, il est important d’avoir en temps réel, toutes les informations afférentes au produit et à son approvisionnement, afin d’éviter tout désagrément, susceptible de porter atteinte à la stratégie globale de vente et par ricochet d’être dommageable à votre chiffre d’affaires.:
            `,
            `Pour une gestion maitrisée de votre stock de produit, nous assurons :`,
            "La gestion des commandes.",
            "La réception des marchandises.",
            "L’inventaire et contrôle.",
        ], 
        img: "images/G.png",

     }
];

export const testimonies = [
    {
        text: "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
        profil: "images/testimonyProfil.jpg",
        nom:"John Doe",
        work:"Directeur de société"
    },
    {
        text: "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
        profil: "images/testimonyProfil.jpg",
        nom:"John Doe",
        work:"Directeur de société"
    },
    {
        text: "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page, le texte définitif venant remplacer le faux-texte dès qu'il est prêt ou que la mise en page est achevée. Généralement, on utilise un texte en faux latin, le Lorem ipsum ou Lipsum.",
        profil: "images/testimonyProfil.jpg",
        nom:"John Doe",
        work:"Directeur de société"
    },
];

export const whySChooseUs = [
    {
        title: "NOTRE EQUIPE",
        desc:"Composée de professionnels marketeurs, communicateurs et vendeurs expérimentés, pour vous garantir des interventions commerciales de qualité.",
    },
    {
        title: "NOTRE LOGISTIQUE",
        desc:"Composée de locaux professionnels, de deux boutiques vitrine et de plusieurs véhicules commerciaux, pour un déploiement commercial maitrisé.",
    },
    {
        title: "NOTRE CARNET D’ADRESSE",
        desc:"Composé d’une dizaine de consultants commerciaux et de plus de 300 boutiques partenaires, réparties stratégiquement sur toute l’étendue du territoire national..",
    },
    {
        title: "NOTRE POLITIQUE TARIFAIRE",
        desc:"Selon vos besoins, personnalisable et adaptable à votre budget et à votre objectif, afin de vous assurer un rapport qualité-prix imbattable sur le marché.",
    },
];

export const clients = 
[
    {
        img: "images/Ecobank.png",
    },
    {
        img: "images/moov.jpg",
     },
    {
        img: "images/coin afrique.png",
     },
    {
        img: "images/Paygate.png",
     }
];


export const Partenaires = 
[
    {
        icon: "images/Togocom.png",
        title: 'Nom du projet',
        subtitles:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        img: "images/ProjetImage.png",
        infoImg: "images/DialogHeader.png",
        infoTitle: "Projet 1",
        infoSubTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
    },
    {
        icon: "images/Togocom.png",
        title: 'Nom du projet',
        subtitles:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.",
        img: "images/ProjetImage.png",
        infoImg: "images/DialogHeader.png",
        infoTitle: "Projet 2",
        infoSubTitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
     },
    
];

export const PartenairesLogo = 
[
    {
        img: "images/Cimco.png",
    },
    {
        img: "images/Ecobank.png",
     },
     {
        img: "images/Gozem.png",
    },
    {
        img: "images/LabiNational.png",
     },
     {
        img: "images/Moov.png",
    },
    {
        img: "images/Paygate.png",
     },
     {
        img: "images/Pia.png",
    },
    {
        img: "images/Renaissance.png",
     },
     {
        img: "images/Sodigaz.png",
    },
    {
        img: "images/Sogea.png",
     },
     {
        img: "images/Togocom.png",
    },
    {
        img: "images/Zener.png",
     },
    
];

