import { styled, Typography } from "@mui/material";

const WhyChooseUsCardContainer = styled("div")(() => ({
    backgroundColor: "#F27405",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: "10px",
    borderRadius: "5px",
    paddingTop: "20px",
    paddingBottom: "20px",
}));
const WhyChooseUsImg = styled("img")(() => ({
    height: "70px"
}));
const WhyChooseUsTitle = styled(Typography)(() => ({
    fontSize: "23px",
    fontFamily: "InterBold,sans-serif !important",
    fontWeight: "800",
    color: "black"
}));
const WhyChooseUsDesc = styled(Typography)(() => ({
    width: "70%",
    textAlign: "justify"
}));

interface Props {
    title: string,
    desc: string
}

function WhyChooseUsCard({title, desc}: Props) {
    return (
        <WhyChooseUsCardContainer>
            <WhyChooseUsImg src="/images/done.png"/>
            <WhyChooseUsTitle>{title}</WhyChooseUsTitle>
            <WhyChooseUsDesc>{desc}</WhyChooseUsDesc>
        </WhyChooseUsCardContainer>
    );
}

export default WhyChooseUsCard;