export const OTHER_PAGES = {
    page404: "/404",
    page500: "/500",
};
export const CLIENT_PAGES = {
    home: "/",
    services: "/services",
    partenaires: "/partenaires",
    about:"/about",
    blog:"/blog",
    blogDetailsPage:"/blog/:id",
    contact:"/contact",
};