import { Facebook, LinkedIn, WhatsApp } from "@mui/icons-material";
import { Button, Container, Grid, Snackbar, styled, TextField, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import ContactOverViewOneButton from "../../components/ContactOverViewOneButton";
import SocialMediaButton from "../../components/SocialMediaButton";
import { ContactFormModel } from "../../models/ContactFormModel";
import { addContactForm } from "../../services/firestore/contact-form";
import SectionStyle from "../../styles/SectionStyle";

const ContactFormContainer = styled(SectionStyle)(()=>({
    display: "flex",
    justifyContent: "center",
}));
const ContactFormInner = styled("div")(({theme})=>({
    width: "80%",
    marginTop:"-100px",
    display:"flex",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 7px 9px rgba(0, 0, 0, 0.03)",
    [theme.breakpoints.down("lg")]: {
        width: "95%",
        flexDirection: "column",
    }
}));
const ContactFormTitle = styled(Typography)(() => ({
    fontFamily: "InterBold,sans-serif !important",
    fontWeight: 700,
    fontSize: "40px",
}));
const ContactFormLocation = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: "22px",
    margin: "20px"
}));
const ContactFormInnerLeft = styled("div")(({theme})=>({
    width:"60%",
    height:"100%",
    padding: "10px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
    }
}));
const ButtonContainer = styled("div")(({theme})=>({
    width:"100%",
    display: "flex",
    [theme.breakpoints.down("lg")]: {
        justifyContent: "center",
    }
}));
const ContactFormInnerRight = styled("div")(({theme})=>({
    width:"40%",
    height:"100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor:"#EDF9FF",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
    }
}));
const Map = styled("iframe")(({theme}) => ({
    width: "400px",
    height: "400px",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
    }
}));

function ContactForm() {
    const [contactForm, setContactForm] = useState<ContactFormModel>({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        company: "",
        message: "",
    });

    const [snackbarIsOpen, setSnackbarIsOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const index = event.target.name as keyof ContactFormModel;
        setContactForm(oldData => {
            let newData = {...oldData};
            newData[index] = event.target.value;
            return newData;
        });
    };

    const submit = () => {
        let dataToSend = {...contactForm};
        dataToSend.date = new Date().getTime();
        if(
            dataToSend.company !== "" && dataToSend.email !== "" && dataToSend.firstName !== "" &&
            dataToSend.lastName !== "" && dataToSend.message !== "" && dataToSend.phone !== ""
        ) {
            addContactForm(dataToSend).then(() => {
                setSnackbarMessage("Message envoyé");
                setSnackbarIsOpen(true);
                setContactForm({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                    company: "",
                    message: "",
                    date: undefined
                });
            }).catch(err => {
                setSnackbarMessage("Une erreur eest survenue lord de l'envoi");
                setSnackbarIsOpen(true);
                console.log(err);
            });
        }else {
            setSnackbarMessage("Erreur : Vous devez remplir tout le formulaire");
            setSnackbarIsOpen(true);
        }

    };
    
    return (
        <ContactFormContainer>
            <ContactFormInner>
            <ContactFormInnerLeft>
                <ContactFormTitle sx={{marginLeft:"30px"}}>Envoyez nous un message</ContactFormTitle>
                <Container>
                    <Grid container spacing={1}>
                        <Grid item lg={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                            <TextField name="lastName" value={contactForm.lastName} variant="standard" label={"Nom"} type="text" fullWidth onChange={handleChange}/>
                        </Grid>
                        <Grid item lg={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                            <TextField name="firstName" value={contactForm.firstName} variant="standard" label={"Prénoms"} type="text" fullWidth onChange={handleChange}/>
                        </Grid>
                        <Grid item lg={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                            <TextField name="phone" value={contactForm.phone} variant="standard" label={"Télephone"} type="tel" fullWidth onChange={handleChange}/>
                        </Grid>
                        <Grid item lg={6} xs={12} sx={{display:"flex", flexDirection:"row"}}>
                            <TextField name="email" value={contactForm.email} variant="standard" label={"Email"} type="email" fullWidth onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12} sx={{marginBottom: "10px"}}>
                            <TextField name="company" value={contactForm.company} variant="standard" label={"Nom de société"} fullWidth onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField name="message" value={contactForm.message} variant="standard" label={"Comment pouvons nous vous aidé"} fullWidth  multiline rows={6} onChange={handleChange}/>
                        </Grid>
                    </Grid>
                    <ButtonContainer>
                        <Button variant="contained" sx={{backgroundColor:"#FF8000", width:"200px", height:"50px", marginTop:"50px"}} onClick={submit}>Envoyer</Button>
                    </ButtonContainer>
                </Container>
            </ContactFormInnerLeft>
            <ContactFormInnerRight>
                <ContactFormTitle>Infos de contact</ContactFormTitle>
                <ContactFormLocation>Agoè Minamadou, a côté de l'école GALICHA</ContactFormLocation>
                <ContactOverViewOneButton icone={'images/contactPhoneIcon.png'} text={"+228 93672828 / 98632828"} link="tel:+22893672828"/>
                <ContactOverViewOneButton icone={'images/contactMailIcon.png'} text={"freemarketgroup.tg@gmail.com"} link="mailto:freemarketgroup.tg@gmail.com"/>
                <Container sx={{marginTop: "10px", display:"flex", flexDirection:"row", justifyContent:"space-evenly"}}>
                    <SocialMediaButton children={<WhatsApp fontSize="large"/>} link="https://wa.me/+22893672828"/>
                    <SocialMediaButton children={<LinkedIn fontSize="large"/>} link="https://www.linkedin.com/company/freemarket-groupe/?viewAsMember=true"/>
                    <SocialMediaButton children={<Facebook fontSize="large"/>} link="https://www.facebook.com/profile.php?id=100089742433559&mibextid=ZbWKwL"/>
                </Container>
                <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1983.1528238753301!2d1.183424586553701!3d6.223369399280469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x10215941779c9511%3A0x67df7bf22e7c0828!2sFree%20Market%20Group!5e0!3m2!1sfr!2stg!4v1667728968294!5m2!1sfr!2stg" frameBorder="0" scrolling="no"/>
            </ContactFormInnerRight>
        </ContactFormInner>
        <Snackbar anchorOrigin={{ horizontal: "right", vertical: "bottom" }}  open={snackbarIsOpen} message={snackbarMessage} autoHideDuration={2000} onClose={() => setSnackbarIsOpen(false)}/>
        </ContactFormContainer>
    );
}

export default ContactForm;