import { styled } from '@mui/material';

const OurAgencyImagesContainer=styled("div")(()=>({
    width:"75%",
    position:"relative"
}));
const OurAgencyImagesImg=styled("img")(()=>({
    position:"absolute"
}));
interface Props {
    img1: string,
    img2: string,
}

function OurAgencyImages({img1,img2}: Props) {
    return (
        <OurAgencyImagesContainer>
            <OurAgencyImagesImg src={img1} sx={{ width:"185px", height:"390px", borderRadius:"119px 119px 119px 0px"}}/>
            <OurAgencyImagesImg sx={{backgroundColor:"#05DAF2", width:"32px", height:"115px", borderRadius:"15px 15px 15px 0px", marginLeft:"215px" }}/>
            <OurAgencyImagesImg sx={{backgroundColor:"#FF8000", width:"48px", height:"52px", borderRadius:"28.5px 28.5px 28.5px 0px" , marginLeft:"160px", marginTop:"360px"}}/>
            <OurAgencyImagesImg src={img2} sx={{ width:"295px", height:"260px", borderRadius:"110px 110px 110px 0px",  marginLeft:"215px", marginTop:"125px"}}/>
            <OurAgencyImagesImg sx={{backgroundColor:"#18255D", width:"172px", height:"85px", borderRadius:"37px 37px 0px 37px", marginLeft:"290px", marginTop:"25px"}}/>
        </OurAgencyImagesContainer>
    );
}

export default OurAgencyImages;