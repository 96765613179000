import Page from "../../components/Pages";
import ContactOverView from "../../sections/HomePage/ContactOverView";
import HomeHero from "../../sections/HomePage/HomeHero";
// import Testimonies from "../../sections/HomePage/Testimonies";
import ServicesOverview from "../../sections/HomePage/ServicesOverview";
import OurNews from "../../sections/HomePage/OurNews";
import { useState, useEffect } from "react";
import { useAppSelector, RootState, useAppDispatch } from "../../redux/store";
import { getArticles } from "../../redux/slices/articles";
import PagesLoader from "../../components/PageLoader";
// import OurClients from "../../sections/HomePage/OurClients";

function HomePage() {
  const [isLoading, setIsLoading] = useState(true);
  const articles = useAppSelector((state: RootState) => state.articles.articles);

  const dispatch = useAppDispatch();

  useEffect(() => {
      if(articles.length) {
          setIsLoading(false);
      }else {
          dispatch(getArticles()).unwrap().then(() => {
              setIsLoading(false);
          });
      }
      // eslint-disable-next-line
  }, []);

  return (
    <Page title="Accueil">
      <PagesLoader isLoading={isLoading}>
        <HomeHero/>
        {/* <OurClients/> */}
        <ServicesOverview/>
        <OurNews/>
        <ContactOverView/>
        {/* <Testimonies/> */}
      </PagesLoader>
    </Page>
  );
}

export default HomePage;
