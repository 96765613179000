import { styled, Typography } from "@mui/material";
import dateConverter from "../../common/dateConverter";
import BaseHero from "../../components/BaseHero";
import { ArticleModel } from "../../models/ArticleModel";


const BlogDetailsHeroCat = styled(Typography)(() => ({
    width: "50%",
    fontSize: "13px",
    fontWeight: 800,
    textAlign: "center",
    color: "#FF8000"
}));
const BlogDetailsHeroTitle = styled(Typography)(({theme}) => ({
    fontFamily: "InterBold,sans-serif !important",
    width: "50%",
    fontSize: "36px",
    fontWeight: 800,
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
        width: "90%"
    }
}));
const BlogDetailsHeroSubTitle = styled(Typography)(({theme}) => ({
    width: "50%",
    fontSize: "20px",
    fontWeight: 800,
    textAlign: "center",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
        width: "90%"
    }
}));
interface Props {
    article?: ArticleModel,
}

function BlogDetailsHero({article}: Props) {
    return (
        <BaseHero sx={{height: "60vh", backgroundImage: `url(${article?.cover}) !important`}}>
            <BlogDetailsHeroCat variant="h4">{article?.category} - {dateConverter({timestamp: article?.date, action: "toDateString"})}</BlogDetailsHeroCat>
            <BlogDetailsHeroTitle variant="h1">{article?.title}</BlogDetailsHeroTitle>
            <BlogDetailsHeroSubTitle variant="h2">{article?.subtitle}</BlogDetailsHeroSubTitle>
        </BaseHero>
    );
}

export default BlogDetailsHero;