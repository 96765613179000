import Page from "../../components/Pages";
import ServicesDetails from "../../components/ServicesDetails";
import WhyChooseUs from "../../components/WhyChooseUs";
// import OurServices from "../../sections/ServicesPage/OurServices";
import OurServicesVideo from "../../sections/ServicesPage/OurServicesVideo";
import ServicesHero from "../../sections/ServicesPage/ServicesHero";

function ServicesPage() {
    return (
        <Page title="Services">
            <ServicesHero/>
            <OurServicesVideo/>
            {/* <OurServices/> */}
            <WhyChooseUs/>
            <ServicesDetails/>
        </Page>
    );
} 
 
export default ServicesPage;