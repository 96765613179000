import { styled, Typography } from "@mui/material";
import BaseHero from "../../components/BaseHero";

const ContactHeroTitle = styled(Typography)(() => ({
    width: "50%",
    fontSize: "20px",
    fontWeight: 800,
    textAlign: "center",
    color: "#FF8000"
}));
const ContactHeroSubTitle = styled(Typography)(({theme}) => ({
    width: "50%",
    fontSize: "36px",
    fontWeight: 800,
    textAlign: "center",
    marginTop: "10px",
    [theme.breakpoints.down("lg")]: {
        width: "90%"
    }
}));

function ContactHero() {
    return (
        <BaseHero>
            <ContactHeroTitle variant="h1">CONTACT</ContactHeroTitle>
            <ContactHeroSubTitle variant="h3">Nous sommes votre équipe commerciale</ContactHeroSubTitle>
        </BaseHero>
    );
}

export default ContactHero;