import { Paper, styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
    cover: string;
    category: string;
    date: string;
    title: string;
    id: string;
}
const BlogCarouselItemContainer=styled(Paper)(()=>({
    width:"100%",
    height:"100%",
    backgroundSize:"cover",
    backgroundRepeat:"no-repeat",
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-end",
    paddingLeft:"50px",
    cursor: "pointer"
}));
const BlogItemText=styled("div")(()=>({
    display:"flex",
    justifyContent:"flex-start",
}));

function BlogCarouselItem({cover,category,date,title,id}: Props) {
    const navigate = useNavigate();

    function goToArticleDetails (id: string) {
        navigate(`/blog/${id}`);
    }
    
    return (
        <BlogCarouselItemContainer onClick={() => goToArticleDetails(id)} sx={{backgroundImage:"linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, #000000 100%),url('"+cover+"')",}}>
            <BlogItemText>
                <Typography  variant="subtitle2" color={"#FF8000"} component="span" fontSize={"16px"} fontWeight={"400"}>{category}</Typography>
                <Typography  variant="body1" color={"white"} component="span" fontSize={"16px"} fontWeight={"200"}>	&nbsp;- {date}</Typography>
            </BlogItemText>
            <Typography  variant="subtitle2" color={"white"} component="span" fontSize={"50px"} fontWeight={"400"} marginBottom={"45px"}>{title}</Typography>
        </BlogCarouselItemContainer>
    );
}

export default BlogCarouselItem;