import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ArticleModel } from "../../models/ArticleModel";
import * as articles from "../../services/firestore/articles";

// Thunks
export const getArticles = createAsyncThunk(
    "articles/getArticles",
    async () => {
        return await articles.getArticles();
    }
);

interface UsersState {
    articles: Array<ArticleModel>,
};

const initialState: UsersState = {
    articles: [],
};

const usersSlice = createSlice({
    name: "articles",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getArticles.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.articles = payload;
        });
    }
});

// export const { } = usersSlice.actions;
export default usersSlice.reducer;