import { Box } from "@mui/material";
import { ClientMenuItems } from "../../common/MenuConfig";
import HeaderMenuItem from "./HeaderMenuItem";

function Menu() {
    return (
        <Box sx={{display: "flex"}}>
            {ClientMenuItems.map((menu, index) => <HeaderMenuItem key={index} title={menu.title} path={menu.path}/>)}
        </Box>
    );
}

export default Menu;