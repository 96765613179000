import { styled } from '@mui/material';
import dateConverter from '../../common/dateConverter';
import BlogCard from '../../components/BlogCard';
import { RootState, useAppSelector } from '../../redux/store';
import SectionStyle from '../../styles/SectionStyle';

const BlogPageCardsContainer = styled(SectionStyle)(({theme})=>({
    display: "grid",
    gridGap: "30px",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "minmax(100px, auto)",
    [theme.breakpoints.down("lg")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
    }
}));

function BlogPageCards() {
    const articles = useAppSelector((state: RootState) => state.articles.articles);

    return (
        <BlogPageCardsContainer>
            {
                articles.slice(0,8).map((oneBlog, index)=> <BlogCard id={oneBlog.id} key={index} index={index} image={oneBlog.cover} date={dateConverter({timestamp: oneBlog.date, action: "toDateString"})!.toString()} subtitle={oneBlog.subtitle}/>)
            }
        </BlogPageCardsContainer>
    );
}

export default BlogPageCards;