import { styled, Typography } from '@mui/material';

const ServiceOverViewContainer = styled("div")(()=>({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
}));
const OurServicesOverViewImage = styled ("img")(()=>({
    width: "160px",
    height: "160px",
}));
const ServiceOverViewTexts = styled("div")(()=>({
    width: "100%",
    display: "flex",
    flexDirection: "column",
}));
const ServiceOverViewSubTitle = styled(Typography)(()=>({
    fontSize: "15px",
    fontWeight: 400,
    color: "#808080",
    marginTop: "20px",
    textAlign: "justify"
}));
const ServiceOverViewSubTitle2 = styled(ServiceOverViewSubTitle)(()=>({
    marginTop: "10px",
}));
interface Props {
    icon: string,
    title: string,
    subtitle: string,
    subtitle2?: string,
};

function ServiceOverView({icon, subtitle, subtitle2}: Props) {
    return (
        <ServiceOverViewContainer>
            <OurServicesOverViewImage src={icon}/>
            <ServiceOverViewTexts>
                <ServiceOverViewSubTitle variant='subtitle1'>{subtitle}</ServiceOverViewSubTitle>
                <ServiceOverViewSubTitle2 variant='subtitle1'>{subtitle2}</ServiceOverViewSubTitle2>
            </ServiceOverViewTexts>
        </ServiceOverViewContainer>
    );
}

export default ServiceOverView;