import { Container, Grid } from "@mui/material";
import { Partenaires } from "../common/data";
import OurPartenersDetailsImages from "./OurPartenersDetailsImages";

function OurPaternersList() {
    return (
        <div>
             <Container>
            <Grid container>
                {
                    Partenaires.map((part, index)=><Grid key={index}item xs> <OurPartenersDetailsImages icon={part.icon} title={part.title} infoImg={part.infoImg} subtitle={part.subtitles} infoSubTitle={part.infoSubTitle} infoTitle={part.infoTitle}  img={part.img} /></Grid>)
                }
            </Grid>
        </Container>
        </div>
    );
}

export default OurPaternersList;