import { styled, SxProps } from "@mui/material";

const HomeSatisfactionContainer = styled("div")(() => ({
    width: "175px",
    height: "67px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft:'20px',
    backgroundColor: "#FF8000",
    borderRadius: "33.5px 0px 0px 33.5px"
}));
interface Props {
    sx?: SxProps
}

function HomeSatisfaction({sx = {}}: Props) {
    return (
        <HomeSatisfactionContainer sx={{...sx}}>
            {/* <Typography variant="h6" sx={{fontWeight: 'bold', fontSize:"15px"}}>100%</Typography> */}
            {/* <Typography variant="h6" sx={{fontWeight: 'bold', fontSize:"15px"}}>Satisfaction</Typography> */}
        </HomeSatisfactionContainer>
    );
}

export default HomeSatisfaction;