import { Container, Grid } from "@mui/material";
import { PartenairesLogo } from "../common/data";
import OurPartnersLogo from "./OurPartnersLogo";

function OurParternersLogoList() {
    return (
        <div>
            <Container>
            <Grid container>
                {
                    PartenairesLogo.map((clipartlogo, index)=><Grid key={index}item xs ><OurPartnersLogo  icon={clipartlogo.img}/></Grid>)
                }
            </Grid>
        </Container>
        </div>
    );
}

export default OurParternersLogoList;