import { Menu } from "@mui/icons-material";
import { ClickAwayListener, Container, IconButton, styled } from "@mui/material";
import { useState } from "react";
import Fade from "../../animation/Fade";
import { ClientMenuItems, HeaderConfig } from "../../common/MenuConfig";
import FadeVariants from "../../variants/FadeVariants";
import HeaderMenuItem from "./HeaderMenuItem";

const MenuMobileContainer = styled(Container)(() => ({
    position: "relative",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
}));
const MenuMobileBtn = styled(IconButton)(() => ({
    color: "#F27405",
    '&:hover': {
        color: '#F27405',
    },
}));
const MenuMobileItemContainer = styled("div")(() => ({
    position: "absolute",
    top: HeaderConfig.HEIGHT,
    right: "5px",
    minWidth: "200px",
    minHeight: "250px",
    display: "none",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.95)",
    backdropFilter: "blur(30px)",
    boxShadow: "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px"
}));

function MenuMobile() {
    const [menuIsVisible, setMenuIsVisible] = useState(false);

    const toggleMenu = () => {
        setMenuIsVisible(!menuIsVisible);
    }

    const onClickAway = () => {
        setMenuIsVisible(false);
    }

    return (
        <ClickAwayListener onClickAway={onClickAway}>
            <MenuMobileContainer>
                <MenuMobileBtn onClick={toggleMenu} children={<Menu sx={{fontSize: "35px"}}/>}/>
                    <MenuMobileItemContainer sx={{display: menuIsVisible ? "flex" : "none"}}>
                        {ClientMenuItems.map((menu, index) => {
                            return <Fade variants={FadeVariants({durationIn: index * .3}).inRight} key={index}><HeaderMenuItem sx={{marginRight: "0px"}} onClick={toggleMenu} title={menu.title} path={menu.path}/></Fade>
                        })}
                </MenuMobileItemContainer>
            </MenuMobileContainer>
        </ClickAwayListener>
    );
}

export default MenuMobile;