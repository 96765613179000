import { styled, SxProps } from "@mui/material";
import { ReactNode } from "react";
import SectionStyle from "../styles/SectionStyle";

const BaseHeroContainer = styled(SectionStyle)(() => ({
    width: "100%",
    height: "80vh",
    padding: "0px",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url(images/headBack.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
}));
const BGFMask = styled("div")(() => ({
    width: "100%",
    height: "100%",
    display: "inherit",
    flexDirection: "inherit",
    justifyContent: "inherit",
    alignItems: "inherit",
    backgroundColor: "rgba(0,0,0,.75)"
}));
interface Props {
    children: ReactNode,
    sx?: SxProps,
    bGFMaskSx?: SxProps,
}
function BaseHero({children, sx = {}, bGFMaskSx}: Props) {
    return (
        <BaseHeroContainer sx={{backgroundImage: "url(images/headBack.png)", ...sx}}>
            <BGFMask sx={{...bGFMaskSx}}>
                {children}
            </BGFMask>
        </BaseHeroContainer>
    );
}

export default BaseHero;