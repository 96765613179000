import { styled, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ArticleContainer= styled("div")(()=>({
    width: "318px",
    height: "380px",
    borderRadius:"20px",
    boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",
    cursor: "pointer"
}));
const ArticleImage = styled("img")(()=>({
    width:"100%",
    height:"210px",
    borderRadius:"20px 20px 0px 0px",
}));
const DateContainer = styled("div")(()=>({
    display: "flex",
    alignItems: "center"
}));
const DateIcon = styled("img")(()=>({
    margin:"10px"
}));
const Date = styled(Typography)(()=>({
    color: "#808080",
    fontSize: "12px",
    fontWeight: 700,
}));
const ArticleTitle = styled(Typography)(()=>({
    marginLeft: "10px",
    fontSize: "20px",
    fontWeight: 700,
}));
const ArticleResume = styled(Typography)(()=>({
    marginLeft: "10px",
    color: "#808080",
    fontSize: "13px",
}));
interface Props {
    image: string,
    date: string,
    title: string,
    subtitle: string,
    id: string,
}

function Article({image,date,title,subtitle,id}: Props) {
    const navigate = useNavigate();

    function goToArticleDetails (id: string) {
        navigate(`/blog/${id}`);
    }

    return (
        <ArticleContainer onClick={() => goToArticleDetails(id)}>
            <ArticleImage src={image}/>
            <DateContainer>
                <DateIcon src="images/dateIcone.png"/>
                <Date>{date}</Date>
            </DateContainer>
            <ArticleTitle>{title}</ArticleTitle>
            <ArticleResume>{subtitle}</ArticleResume>
        </ArticleContainer>
    );
}

export default Article;