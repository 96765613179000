import { styled, Typography } from "@mui/material";
import { whySChooseUs } from "../common/data";
import SectionStyle from "../styles/SectionStyle";
import WhyChooseUsCard from "./WhyChooseUsCard";

const WhyChooseUsContainer = styled(SectionStyle)(()=>({
    marginTop: '69px',
    marginBottom: '96px',
}));
const WhyChooseUsList = styled(SectionStyle)(({theme})=>({
    display: "grid",
    gridGap: "30px",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "minmax(100px, auto)",
    [theme.breakpoints.down("lg")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
    }
}));
const WhyChooseUsTitle = styled(Typography)(() => ({
    fontSize: "30px",
    fontWeight: 800,
    textAlign: "center",
    marginBottom: "20px"
}));

function WhyChooseUs() {
    return (
        <WhyChooseUsContainer id="DetailSection">
            <WhyChooseUsTitle>
                Pourquoi nous choisir
            </WhyChooseUsTitle>
            <WhyChooseUsList>
                {
                    whySChooseUs.map((one, index) => <WhyChooseUsCard key={index} title={one.title} desc={one.desc}/>)
                }
            </WhyChooseUsList>
        </WhyChooseUsContainer>
    );
}

export default WhyChooseUs;