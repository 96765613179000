import { styled } from "@mui/material";
// import OurTeamOverView from "../../components/OurTeamOverView";
import ServiceOverView from "../../components/ServiceOverView";
import SectionStyle from "../../styles/SectionStyle";

const ServiceOverViewContainer = styled(SectionStyle)(() => ({
    minHeight: "700px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-around",
    marginTop: "100px"
}));
const ServiceOvrviewLeftElement = styled("div")(({theme}) => ({
    width: "100%",
    minHeight: "700px",
    display: "flex",
    gap: "30px",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }
}));
const ServiceOvrviewTexts = styled("div")(() => ({
    fontSize: "18px",
    textAlign: "center",
    marginBottom: "30px"
}));
const ServiceOvrviewTexts2 = styled("div")(({theme}) => ({
    width: "70%",
    fontSize: "18px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    margin: "auto",
    textAlign: "justify",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
    }
}));
const ServiceOvrviewTitle = styled("span")(({theme}) => ({
    fontSize: "30px",
    fontWeight: 800,
    [theme.breakpoints.down("md")]: {
        fontSize: "39px",
    }
}));
const ServiceOvrviewSubTitle = styled("span")(({theme}) => ({
    fontSize: "30px",
    marginLeft: "10px",
    fontWeight: 800,
    color:"#FF8000",
    [theme.breakpoints.down("md")]: {
        fontSize: "39px",
    }
}));
// const ServiceOvrviewRightElement = styled("div")(({theme}) => ({
//     width: "50%",
//     minHeight: "700px",
//     position: "relative",
//     display: "flex",
//     justifyContent: "space-between",
//     overflow: "hidden",
//     [theme.breakpoints.down("md")]: {
//         display: "none"
//     }
// }));
// const OurTeamOverViewImg= styled("img")(()=>({
//     position:"absolute",
// }))
const ServiceOverViewList = styled("div")(({theme})=>({
    display: "grid",
    gridGap: "30px",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridAutoRows: "minmax(100px, auto)",
    [theme.breakpoints.down("lg")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "repeat(1, 1fr)",
    }
}))

function ServicesOverview() {
    return (
        <ServiceOverViewContainer>
            <ServiceOvrviewLeftElement>
                <ServiceOvrviewTexts>
                    <ServiceOvrviewTitle>NOUS PARTONS DE</ServiceOvrviewTitle>
                    <ServiceOvrviewSubTitle>VOS ACQUIS</ServiceOvrviewSubTitle>
                </ServiceOvrviewTexts>
                <ServiceOvrviewTexts2>
                    Au free Market Group, notre démarche intègre la proposition et le déploiement d’un plan marketing personnalisé. 
                    Pour se faire, nous vous mettons à contribution, afin de faire la bonne lecture de votre situation commerciale actuelle, de définir notre destination commune, le cheminement et les actions commerciales idoines.  
                </ServiceOvrviewTexts2>
                <ServiceOverViewList>
                    <ServiceOverView icon="/images/DIA.png" title="Diagnostic" subtitle="Etape incontournable dans notre quête de solutions commerciales et marketing adaptées à vos réalités, il s’agit prioritairement pour Free Market Group de mieux connaitre votre entreprise, votre produit ou votre service." subtitle2="Ensemble avec vous, nous analysons votre environnement interne et externe afin de connaitre et influencer les forces, faiblesses, opportunités et menaces de votre système de gestion commerciale actuelle et de votre position sur le marché."/>
                    <ServiceOverView icon="/images/STARTEGIE.png" title="Stratégie" subtitle="Suite logique de notre diagnostic, ici, nous déterminons, toujours de concert avec vous l’ensemble des décisions et actions susceptibles de représenter des solutions commerciales et marketing, en réponses aux conclusions de nos études et analyses commerciales." subtitle2="De façon concrète, nous définissons les objectifs, les priorités commerciales et marketing, les budgets et les différents mix qui seront les plus bénéfiques pour nos futures actions."/>
                    <ServiceOverView icon="/images/ACTION.png" title="Actions" subtitle="A cette étape, nos équipes de professionnels spécialistes des ventes et du marketing digital seront mises à contribution, afin de mettre en œuvre un ensemble d’actions de commercialisation coordonnées et éprouvées." subtitle2="Il s’agira ici, pour Free Market Group, de vendre, vendre et vendre, afin d’atteindre les objectifs de vente préalablement définis dans la stratégie.
Le cœur de notre métier c’est la vente."/>
                    <ServiceOverView icon="/images/RESULTAT.png" title="Suivi" subtitle="Nous intégrons dans notre démarche, une analyse permanente de notre progression vers la réalisation des objectifs, à travers la mise en place préalable des indicateurs de performance adaptés." subtitle2="Il s’agit d’un ensemble d’éléments et informations nouvelles, dont l’analyse à court, moyen et long terme, entraîne des décisions et nouvelles dispositions commerciales et marketing, qui augmenteront la possibilité d'atteindre les résultats escomptés."/>
                </ServiceOverViewList>
            </ServiceOvrviewLeftElement>
            {/* <ServiceOvrviewRightElement>
                <OurTeamOverViewImg src="images/Rectangle 46.png" sx={{width:"26px", height:"26px", marginTop:"110px", marginLeft:"80px"}}/>
                <OurTeamOverViewImg src="images/Ellipse 26.png" sx={{width:"16px", height:"16px",marginTop:"55px" , marginLeft:"210px"}}/>
                <OurTeamOverViewImg src="images/Ellipse 27.png" sx={{width:"26px", height:"26px",marginTop:"110px" , marginLeft:"320px"}}/>
                <OurTeamOverViewImg src="images/overviewSectionElipse.png" sx={{width:"390px", height:"450px", marginTop:"150px",marginRight:"-375px", marginLeft:"50px"}}/>
                <OurTeamOverViewImg src="images/overview sect.png" sx={{width:"340px", height:"600px", marginRight:"-300px", marginLeft:"60px"}}/> */}
                {/* <OurTeamOverView/> */}
            {/* </ServiceOvrviewRightElement> */}
        </ServiceOverViewContainer>
    );
}

export default ServicesOverview;