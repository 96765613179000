import { Navigate, useLocation, useRoutes } from "react-router-dom";
import OthersLayout from "../layouts/OthersLayout";
import ClientLayout from "../layouts/ClientLayout";
import HomePage from "../pages/client/HomePage";
import { CLIENT_PAGES } from "./paths";
import ServicesPage from "../pages/client/ServicesPage";
import PartenairesPage from "../pages/client/PartenairesPage";
import AboutPage from "../pages/client/AboutPage";
import BlogPage from "../pages/client/BlogPage";
import ContactPage from "../pages/client/ContactPage";
import { useEffect } from "react";
import BlogDetailsPage from "../pages/client/BlogDetailsPage";

export default function Router() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0,0);
  }, [pathname]);

  return useRoutes([
    //
    {
      path: "*",
      element: <OthersLayout />,
      children: [
      ],
    },
    //
    {
      path: CLIENT_PAGES.home,
      element: <ClientLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: CLIENT_PAGES.services, element: <ServicesPage /> },
        { path: CLIENT_PAGES.partenaires, element: <PartenairesPage/> },
        { path: CLIENT_PAGES.blog, element: <BlogPage /> },
        { path: CLIENT_PAGES.blogDetailsPage, element: <BlogDetailsPage /> },
        { path: CLIENT_PAGES.about, element: <AboutPage /> },
        { path: CLIENT_PAGES.contact, element: <ContactPage /> },
      ],
    },
    //
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}