import { styled, Typography } from "@mui/material";
import BaseHero from "../../components/BaseHero";


const ServiceHeroTitle = styled(Typography)(() => ({
    width: "50%",
    fontSize: "20px",
    fontWeight: 800,
    textAlign: "center",
    color: "#FF8000"
}));
const ServiceHeroSubTitle = styled(Typography)(({theme}) => ({
    width: "50%",
    fontSize: "36px",
    fontWeight: 800,
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "100px",
    [theme.breakpoints.down("lg")]: {
        width: "90%"
    }
}));
const ServiceButton = styled("a")(() => ({
    minWidth: "34px",
    minHeight: "72px",
    marginBottom: "50px"
}));

function ServicesHero() {
    return (
        <BaseHero sx={{justifyContent: "flex-end"}}>
            <ServiceHeroTitle variant="h1">SERVICES</ServiceHeroTitle>
            <ServiceHeroSubTitle variant="h3">Un partenaire au cœur de votre développement commercial</ServiceHeroSubTitle>
            <ServiceButton href="#DetailSection" sx={{background: "url(images/Mouse.png)"}}/>
        </BaseHero>
    );
}

export default ServicesHero;