import { styled } from "@mui/material";
import { HeaderConfig } from "../common/MenuConfig";
import Logo from "../components/Logo";
import useOffSetTop from "../hooks/useOffSetTop";

const HeaderLogoAnimContainer = styled("div")(() => ({
    height: "100%",
    position: "relative",
}));
const LogoAnimContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "0px",
    width: "120px",
    height: "100%",
    marginLeft: "40px",
    display:  "flex",
    alignItems:"center",
    justifyContent: "center",
    transition: theme.transitions.create(["height", "boxShadow", "backgroundColor"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
    }),
}));

function HeaderLogoAnim() {
    const isOffset = useOffSetTop(HeaderConfig.HEIGHT);

    return (
        <HeaderLogoAnimContainer>
            <LogoAnimContainer sx={{...(isOffset ? {display: "none"} : {width: "150px"})}}>
                {/* <Logo sx={{width: "100%", height: "100%"}} logoWhite={true}/> */}
                <Logo sx={{width: "100%", height: "100%"}}/>
            </LogoAnimContainer>
            <LogoAnimContainer sx={{...(!isOffset ? {display: "none"} : {width: "150px"})}}>
                <Logo sx={{width: "100%", height: "100%"}}/>
            </LogoAnimContainer>
        </HeaderLogoAnimContainer>
    );
}

export default HeaderLogoAnim;