import {styled, Typography } from "@mui/material";
import OurPaternersList from "./OurPaternersList";

const OurPartenersDetailsContainer = styled("div")(({theme}) => ({
    display: "flex",
    paddingTop:"100px",
    paddingLeft: "100px",
    [theme.breakpoints.down("sm")]: {
        display: "block",
        textAlign: "center",
        paddingTop: "5vh",
        padding: "2vw",
    }
}));
const OurPartenersDetailsLeftText = styled("div")(() => ({
   display: "block",
}));

function OurPartenersDetails() {
    return (
        <OurPartenersDetailsContainer sx={{paddingBottom: "40px"}}>
            <OurPartenersDetailsLeftText>
                <Typography sx={{fontWeight: "800", fontSize: "36px", paddingBottom: "25px"}}>
                    Nos partenaires<br/>en Détails
                </Typography>
                <Typography sx={{fontSize: "20px", color: "background: #808080"}}>
                    Lorem ipsum dolor sit amet,<br/> consectetur adipiscing elit.
                </Typography>
            </OurPartenersDetailsLeftText>
            <OurPaternersList/>
        </OurPartenersDetailsContainer>
    );
}

export default OurPartenersDetails;