import { styled, SxProps, Typography } from "@mui/material";
import { ServiceModel } from "../models/ServiceModel";

const ServiceDetailsContainer = styled('div')(({theme}) => ({
    minHeight: "400px",
    display: 'flex',
    flexDirection: "column",
    justifyContent:  "space-around",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
        paddingTop:  "20px",
    }
}));
const ServiceDetailsImageContainer = styled('div')(()  =>({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
}));
const ServiceDetailsImage = styled('img')(({theme}) =>({
    width: '400px',
    margin: "50px",
    [theme.breakpoints.down("md")]: {
        width: '300px',
    },
}));
const Descs = styled('div')(({theme}) => ({
    display: 'flex',
    marginTop: "20px",
    width: "70%",
    [theme.breakpoints.down("lg")]: {
        display: 'block',
        width: "90%",
    }
}));
const ServiceeDetailsTitle = styled(Typography)(({theme}) => ({
    fontSize: "30px",
    fontWeight: 800,
    color: "#FF8000",
    fontFamily: "InterBold,sans-serif !important",
    [theme.breakpoints.down("lg")]: {
        textAlign: "center",
        fontSize: "24px",
    }
}));
const Desc = styled(Typography)(({theme}) => ({
    minWidth: "100%",
    fontSize: "20px",
    fontWeight: 400,
    color: "#808080",
    marginTop: "5px",
    [theme.breakpoints.down("lg")]: {
        marginTop: "20px",
    }
}));
const DescItem = styled(Desc)(({theme}) => ({
    backgroundColor: "#FF8000",
    color: "black",
    paddingLeft: "10px",
    fontWeight: "bold",
}));
const DescLR = styled("div")(({theme}) => ({
    width: "50%",
    marginLeft: "80px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        marginLeft: "0px"
    }
}));
interface Props {
    title: string,
    subTitle: string,
    descs: Array<string>,
    img: string,
    service: ServiceModel,
    sx? : SxProps,
}

function ServiceDetails({title, subTitle, descs, img, service, sx = {}} : Props) {
    return (
        <ServiceDetailsContainer sx={{ ...sx}}>
            <ServiceDetailsImageContainer>
                <ServiceDetailsImage src={img}/>
                    <ServiceeDetailsTitle>
                        {subTitle}
                    </ServiceeDetailsTitle>
            </ServiceDetailsImageContainer>
            <Descs>
                <DescLR>
                    <Desc sx={{textAlign: "justify", marginBottom: "20px"}}>
                        {descs[0]}
                    </Desc>
                </DescLR>
                <DescLR>
                    <Desc sx={{textAlign: "justify", marginBottom: "20px", color: "black"}}>
                        {descs[1]}
                    </Desc>
                    {descs.slice(2).map((desc, index) =>
                        <DescItem key={index}>
                            {desc}
                        </DescItem>
                    )}
                </DescLR>
            </Descs>
        </ServiceDetailsContainer>
    );
}

export default ServiceDetails;