import { Player } from "@lottiefiles/react-lottie-player";
import { styled, Typography } from "@mui/material";
import { useState } from "react";
import SectionStyle from "../../styles/SectionStyle";
import { CLIENT_PAGES } from "../../routes/paths";
import { Link } from "react-router-dom";

const OurServicesVideoContainer = styled(SectionStyle)(({theme}) => ({
    width: "calc(100% - 100px)",
    paddingLeft: "50px",
    paddingRight: "50px",
    minHeight: "80vh",
    color: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FF800012",
    [theme.breakpoints.down("lg")]: {
        flexDirection: "column"
    },
}));
const OurServicesVideoLeftContainer = styled("div")(({theme}) => ({
    minWidth: "600px",
    maxWidth: "45%",
    minHeight: "500px",
    margin: "40px",
    marginTop: "0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        minWidth: "300px",
    },
}));
const OurServicesVideoLeftTitle = styled(Typography)(({theme}) => ({
    fontFamily: "InterBold,sans-serif !important",
    fontSize: "64px",
    fontWeight: 800,
    color: "#000000",
    [theme.breakpoints.down("md")]: {
        fontSize: "2.5em",
        textAlign: "center",
    },
}));
const OurServicesVideoLeftText = styled(Typography)(({theme}) => ({
    maxWidth: "700px",
    fontSize: "1.3em",
    color: "#808080",
    [theme.breakpoints.down("md")]: {
        fontSize: "1.2em",
        textAlign: "center"
    },
}));
const OurServicesVideoRightContainer = styled("div")(({theme}) => ({
    minWidth: "450px",
    minHeight: "400px",
    margin: "40px",
    marginTop: "0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
        minWidth: "300px",
        minHeight: "200px",
    },
}));
const OurServicesVideoButtonContainer = styled("div")(({theme}) => ({
    [theme.breakpoints.down("md")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    }
}));
const OurServicesVideoButton = styled(Link)(({theme}) => ({
    minWidth: "320px",
    maxWidth: "450px",
    height: "57px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 700,
    borderRadius: "90px",
    backgroundColor: '#FF8000',
    textDecoration: "none",
    color: "white",
    '&:hover': {
        backgroundColor: '#FF8000',
    },
    [theme.breakpoints.down("lg")]: {
        fontSize: "18px",
        minWidth: "320px",
        height: "60px",
    },
}));
const OurServicesVideoImageContainer = styled("div")(({theme}) => ({
    position: "relative",
    width: "70%",
    minWidth: "40vw",
    borderRadius: "33px",
    marginTop: "80px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        marginTop: "0px",
    },
}));
const OurServicesVideoImage = styled("img")(() => ({
    width: "100%",
    objectFit: "cover",
    border: "5px solid black",
    borderRadius: "33px",
}));
const OurServicesVideoVideo = styled("video")(({theme}) => ({
    width: "70%",
    minWidth: "40vw",
    display: "none",
    objectFit: "cover",
    border: "5px solid black",
    borderRadius: "33px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
    },
}));
const OurServicesVideoVideoPlayContainer = styled("div")(() => ({
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}));
const PlayBtn = styled("div")(() => ({
}));
const OurServicesVideoVideoPlay = styled(Player)(() => ({
    width: "300px",
    height: "300px",
    cursor: "pointer",
}));

function OurServicesVideo() {
    const [videoPlaying, setVideoPlaying] = useState(false);

    const playVideo = () => {
        setVideoPlaying(true);
        const video: HTMLMediaElement = document.getElementById("RTVideo") as HTMLMediaElement;
        video?.play();
    }

    return (
        <OurServicesVideoContainer id="home">
            <OurServicesVideoLeftContainer>
                <OurServicesVideoLeftTitle variant="h1">Free Market Group</OurServicesVideoLeftTitle>
                <OurServicesVideoLeftText variant="h4">
                    A votre service, une entreprise, une équipe commerciale complète et qualifiée, pour vous offrir des solutions commerciales efficaces et personnalisées.
                </OurServicesVideoLeftText>
                <OurServicesVideoLeftText variant="h4">
                    Cœur de notre métier, votre développement commercial passe également par une gestion optimale des flux de vos marchandises.
                </OurServicesVideoLeftText>
                <OurServicesVideoLeftText variant="h4">
                    Afin de mieux vous accompagner, nos services intègrent toutes les actions commerciales et la gestion de stock.
                </OurServicesVideoLeftText>
                <OurServicesVideoButtonContainer>
                    <OurServicesVideoButton to={CLIENT_PAGES.contact}>
                        Nous contacter
                    </OurServicesVideoButton>
                </OurServicesVideoButtonContainer>
            </OurServicesVideoLeftContainer>
            <OurServicesVideoRightContainer>
                <OurServicesVideoVideo id="RTVideo" sx={{display: videoPlaying ? "block" : "none"}} controls src="/video/FMG presentation.mp4"/>
                <OurServicesVideoImageContainer sx={{display: !videoPlaying ? "block" : "none"}}>
                    <OurServicesVideoImage src="/images/A.jpg"/>
                    <OurServicesVideoVideoPlayContainer>
                        <PlayBtn onClick={playVideo}>
                            <OurServicesVideoVideoPlay src="/lottiefiles/play.json" autoplay loop/>
                        </PlayBtn>
                    </OurServicesVideoVideoPlayContainer>
                </OurServicesVideoImageContainer>
            </OurServicesVideoRightContainer>
        </OurServicesVideoContainer>
    );
}

export default OurServicesVideo;