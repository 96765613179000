import Page from '../../components/Pages';
import ContactForm from '../../sections/ContactPage/ContactForm';
import ContactHero from '../../sections/ContactPage/ContactHero';

function ContactPage() {
    return (
        <Page title="Contact">
            <ContactHero/>
            <ContactForm/>
        </Page>
    );
}

export default ContactPage;