import {Button, Card, CardActions, CardContent, CardMedia, styled, Typography} from "@mui/material";

const ProjectCards = styled('div')(({theme})=>({
    display: "flex",
    marginLeft: "10vw",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
        display: 'block',
        margin: "auto",
        maringRight: "20px",
        marginTop: "30px"  
    },
    [theme.breakpoints.down("sm")]: {
        display: 'block',
        width: "300px",
        margin: "auto",
        maringRight: "20px",
        marginTop: "30px"  
    },
}));
const ProjectCardsHeader = styled("div")(() => ({
    display: "flex",
}));
const Icon = styled("div")(()=> ({
    width: "42px",
    height: "27px",
    margin: "10px"
}));
interface Props
{
    icon: string,
    title: string,
    subtitle: string,
    img:string,
    infoImg: string,
    infoTitle: string,
    infoSubTitle: string
};


function OurPartenersDetailsImages({icon, title, subtitle, infoImg, infoTitle, infoSubTitle, img}:Props) {
    return (     
    <ProjectCards>
        <Card sx={{borderRadius: "17px", width: "309px"}}>
            <CardMedia component="img" height="140" width="100" image={img}/>
            <CardContent sx={{margin: "10px"}}>  
                <ProjectCardsHeader>
                    <Icon sx={{backgroundImage: "url('"+icon+"')"}} />
                    <Typography variant="h5">{title}</Typography>
                </ProjectCardsHeader>
                <Typography variant="body2">{subtitle}</Typography>
            </CardContent>
            <CardActions>
                <Button sx={{border: "1px solid #FF8000", color: "#FF8000", fontSize: "7px", margin: "10px"}}>
                    LIRE PLUS
                </Button>
            </CardActions>
        </Card>
    </ProjectCards>
    );
}

export default OurPartenersDetailsImages;