import { isSupported } from "./services/analytics";
import Router from "./routes";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    isSupported();
  },[]);

  return (
    <Router/>
  );
}

export default App;
