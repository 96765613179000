import { AppBar } from "@mui/material";
import HeaderAnim from "../../animation/HeaderAnim";
import HeaderLogoAnim from "../../animation/HeaderLogoAnim";
import useResponsive from "../../hooks/useResponsive";
import Menu from "./Menu";
import MenuMobile from "./MenuMobile";

function Header() {
    const isMobile = useResponsive("down", "lg");

    return (
        <AppBar color="transparent" elevation={0}>
            <HeaderAnim>
                <HeaderLogoAnim/>
                {!isMobile && <Menu/>}
                {isMobile && <MenuMobile/>}
            </HeaderAnim>
        </AppBar>
    );
}

export default Header;