import Page from "../../components/Pages";
import AboutHero from "../../sections/AboutPage/AboutHero";
import OurResults from "../../sections/AboutPage/OurResults";
import OurClientsOverView from "../../sections/AboutPage/OurClientsOverView";
import OurAgency from "../../sections/AboutPage/OurAgency";

function AboutPage() {
    return (
        <Page title="A Propos">
            <AboutHero/>
            <OurAgency/>
            <OurResults/>
            <OurClientsOverView/>
        </Page>
    );
}

export default AboutPage;
