import { LinearProgress,styled, Typography } from "@mui/material";

const OurAgencyProgressLinearContainer=styled("div")(()=>({
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
}));
const OurAgencyProgressLinearContainerIndic=styled("div")(()=>({
    display:"flex",
    justifyContent:"space-between",
}));
interface Props {
    title: string;
    pourcentage: string;
    linearValue: number;
}

function OurAgencyProgressLinear({title,pourcentage, linearValue}: Props) {
    return (
        <OurAgencyProgressLinearContainer>
            <OurAgencyProgressLinearContainerIndic>
                <Typography>{title}</Typography>
                <Typography>{pourcentage}</Typography>
            </OurAgencyProgressLinearContainerIndic>
            <LinearProgress variant="determinate" value={linearValue} />
        </OurAgencyProgressLinearContainer>
    );
}

export default OurAgencyProgressLinear;