import { Container, Grid, styled, Typography } from '@mui/material';
import Fade from '../../animation/Fade';
import dateConverter from '../../common/dateConverter';
import Article from '../../components/Article';
import { RootState, useAppSelector } from '../../redux/store';
import SectionStyle from '../../styles/SectionStyle';
import FadeVariants from '../../variants/FadeVariants';

const OurNewsContainer = styled(SectionStyle)(() => ({
    marginTop: "100px",
    marginBottom: "100px",
}));
const OurNewsTitle = styled(Typography)(() => ({
    marginBottom: "60px",
    textAlign: "center",
    fontSize: "48px",
    fontWeight: 700,
}));

function OurNews() {
    const articles = useAppSelector((state: RootState) => state.articles.articles);
    return (
        <OurNewsContainer>
            <OurNewsTitle variant='h3'>NOS DERNIERS BLOGS</OurNewsTitle>
            <Container>
                <Grid container>
                    {
                        articles.slice(0,3).map((oneArticle, index) => <Grid key={index} item xs display="flex" justifyContent="center" marginTop="20px">
                            <Fade variants={FadeVariants({}).inUp}>
                                <Article id={oneArticle.id} image={oneArticle.cover} date={dateConverter({timestamp: oneArticle.date, action: "toDateString"})!.toString()} title={oneArticle.title} subtitle={oneArticle.subtitle}/>
                            </Fade>
                        </Grid>)
                    }
                </Grid>
            </Container>
        </OurNewsContainer>
    );
}

export default OurNews;