import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PagesLoader from "../../components/PageLoader";
import Page from "../../components/Pages";
import { ArticleModel } from "../../models/ArticleModel";
import { getArticles } from "../../redux/slices/articles";
import { RootState, useAppDispatch, useAppSelector } from "../../redux/store";
import BlogDetailsContent from "../../sections/BlogDetailsPage/BlogDetailsContent";
import BlogDetailsHero from "../../sections/BlogDetailsPage/BlogDetailsHero";

function BlogDetailsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const articles = useAppSelector((state: RootState) => state.articles.articles);
    const params = useParams();
    const [article, setArticle] = useState<ArticleModel | undefined>(undefined);
  
    const dispatch = useAppDispatch();
  
    useEffect(() => {
        if(articles.length) {
            setArticle(articles.find(article => article?.id === params.id));
            setIsLoading(false);
        }else {
            dispatch(getArticles()).unwrap().then(() => {
                setIsLoading(false);
            });
        }
        // eslint-disable-next-line
    }, [isLoading]);

    return (
        <Page title={article?.title ?? "Loading"}>
            <PagesLoader isLoading={isLoading}>
                <BlogDetailsHero article={article}/>
                <BlogDetailsContent article={article}/>
            </PagesLoader>
        </Page>
    );
}

export default BlogDetailsPage;