import OurPartenersDetails from "../../components/OurPartenersDetails";
import OurParternersLogoList from "../../components/OurParternersLogoList";
import Page from "../../components/Pages";
import PartenairesHero from "../../sections/PartenairesPage/PartenairesHero";

function PartenairesPage() {
    return (
        <Page title="Partenaires">
            <PartenairesHero/>
            <OurPartenersDetails/>
            <OurParternersLogoList/>
        </Page>
    );
}

export default PartenairesPage;