import { AccessTime } from "@mui/icons-material";
import Card from '@mui/material/Card';
import {  styled, Typography } from "@mui/material";
import { BLOG_GRADIENTS } from "../common/blogBackgrounds";
import { useNavigate } from "react-router-dom";

const BlogCardContainer= styled(Card)(()=>({
    height:"335px",
    margin:"15px",
    cursor: "pointer"
}));
const BlogCoverText=styled("div")(()=>({
    width:"100%",
    height:"100%",
    display:"flex",
    flexDirection:"column",
    justifyContent:"flex-end",
    backgroundSize:"cover",
}));
const IconText = styled("div")(()=>({
    display:"inline-flex",
}));

interface Props {
    image: string;
    date: string;
    subtitle: string;
    index: number;
    id: string;
}

function getGradient(index: number) {
    if(index % 3 === 0) {
        return BLOG_GRADIENTS.grey;
    }else if(index % 3 === 1) {
        return BLOG_GRADIENTS.black;
    }else if(index % 3 === 2) {
        return BLOG_GRADIENTS.blue;
    }
}

function BlogCard({image, date, subtitle, index, id}: Props) {
    const navigate = useNavigate();

    function goToArticleDetails (id: string) {
        navigate(`/blog/${id}`);
    }
    return (
        <BlogCardContainer onClick={() => goToArticleDetails(id)}>
            <BlogCoverText sx={{backgroundImage: ""+getGradient(index)+",url('"+image+"')", paddingLeft:"20px"}}>
                <IconText sx={{display: "flex", alignItems: "center"}}>
                    <AccessTime sx={{color:"white" , fontSize:17, margin:"2px"}}/><Typography variant="body1" color={"white"} fontWeight={"bold"} fontSize={"12px"}>{date}{index}</Typography>
                </IconText>
                <Typography variant="body1" color={"white"} fontWeight={"bold"} fontSize={"20px"} marginBottom={"20px"}>{subtitle}</Typography>
            </BlogCoverText>
        </BlogCardContainer>
    );
}

export default BlogCard;