import { Container, Grid, styled, Typography } from "@mui/material";
import OurAgencyImages from "../../components/OurAgencyImages";
import OurAgencyProgressLinear from "../../components/OurAgencyProgressLinear";
import SectionStyle from "../../styles/SectionStyle";

const OurAgencyContainer=styled(SectionStyle)(()=>({
    minHeight:"500px",
    display:"flex",
    alignItems:"center"
}));

function OurAgency() {
    return (
        <OurAgencyContainer>
            <Container>
                <Grid container>
                    <Grid item xs>
                        <OurAgencyImages img1={"images/heroImg1.jpg"} img2={"images/heroImg2.jpg"} />
                    </Grid>
                    <Grid item xs sx={{display:"flex",flexDirection:"column", justifyContent:"space-around", height:"350px",margin:"25px"}}>
                        <Typography sx={{ fontWeight:"bold"}}>A Propos</Typography>
                        <Typography variant="h6" sx={{color:"#FF8000", fontWeight:"bold"}}>VOTRE AGENCE WEB CRÉATIVE</Typography>
                        <Typography sx={{ fontWeight:"bold"}}>Depuis 2017, nous concevons des projets sur-mesure pour nos clients</Typography>
                        <Typography>Expérimentez la réussite numérique grâce à un site web professionnel et un marketing intelligent. Notre agence se concentrera exclusivement sur vos performances digitales et sa pleine croissance.</Typography>
                        <OurAgencyProgressLinear title={"Marketing"} pourcentage={"90%"} linearValue={90}/>
                        <OurAgencyProgressLinear title={"Services et conseil"} pourcentage={"75%"} linearValue={75}/>
                    </Grid>
                </Grid>
            </Container>
        </OurAgencyContainer>
    );
}

export default OurAgency;