import { styled } from "@mui/material";
import { ArticleModel } from "../../models/ArticleModel";
import SectionStyle from "../../styles/SectionStyle";

import 'react-quill/dist/quill.snow.css';

const BlogDetailsContentContainer = styled(SectionStyle)(({theme}) => ({
    width: "90%",
    [theme.breakpoints.down("lg")]: {
        width: "90%"
    }
}));
interface Props {
    article?: ArticleModel,
}

function BlogDetailsContent({article}: Props) {
    return (
        <BlogDetailsContentContainer>
            <div className="ql-editor" dangerouslySetInnerHTML={{__html: article?.content ?? ""}}></div>
        </BlogDetailsContentContainer>
    );
}

export default BlogDetailsContent;