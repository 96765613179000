import { OTHER_PAGES, CLIENT_PAGES } from "../routes/paths";

export const OtherMenuItems = [
  {
    title: "404",
    path: OTHER_PAGES.page404,
  },
  {
    title: "500",
    path: OTHER_PAGES.page500,
  },
];

export const ClientMenuItems = [
  {
    title: "Accueil",
    path: CLIENT_PAGES.home
  },
  // {
  //   title: "A propos",
  //   path: CLIENT_PAGES.about,
  // },
  {
    title: "Services",
    path: CLIENT_PAGES.services
  },
  // {
  //   title: "Partenaires",
  //   path: CLIENT_PAGES.partenaires
  // },
  {
    title: "Blog",
    path: CLIENT_PAGES.blog,
  },
  {
    title: "Contact",
    path: CLIENT_PAGES.contact
  },
];

export const HeaderConfig = {
  HEIGHT: 80,
  MAX_HEIGHT: 90,
};
