import { styled } from '@mui/material';
import  { ReactNode } from 'react';

const SocialMediaButtonContainer= styled("a")(()=>({
    color:"black",
}));

interface Props{
    children: ReactNode,
    link: string,
}

function SocialMediaButton({children, link}: Props) {
    return (
        <SocialMediaButtonContainer href={link} target="_blank">
            {children}
        </SocialMediaButtonContainer>
    );
}

export default SocialMediaButton;