import { Facebook, LinkedIn, WhatsApp } from '@mui/icons-material';
import { Grid, styled } from '@mui/material';
import ContactOverViewOneButton from '../../components/ContactOverViewOneButton';
import SocialMediaButton from '../../components/SocialMediaButton';
import SectionStyle from '../../styles/SectionStyle';

const ContactOverViewContainer = styled(SectionStyle)(({theme})=>({
    width: "calc(100% - 100px)",
    minHeight: "400px",
    margin: "auto",
    marginTop: "50px",
    marginBottom: "50px",
    backgroundColor:"#EDF9FF",
    borderRadius:"30px",
    display:"flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("lg")]:{
        width: "calc(100% - 20px)",
        borderRadius:"0px",
        justifyContent: "center",
    }
}));
const ContactOverViewLeftContainer = styled("div")(({theme})=>({
    width: "50%",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    [theme.breakpoints.down("lg")]: {
        alignItems: "center",
        width: "100%",
    }
}));
const ContactOverViewTitleContainer = styled("div")(({theme})=>({
    textAlign: "center",
    fontSize: "40px",
    [theme.breakpoints.down("lg")]:{
    }
}));
const ContactOverViewTitle = styled("span")(()=>({
}));
const ContactOverViewButtonContainer = styled("div")(({theme})=>({
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]:{
        flexWrap: "wrap",
        justifyContent: "center",
    }
}));
const ContactOverViewImage = styled("img")(({theme})=>({
    width: "50%",
    height: "auto",
    marginTop: "-100px",
    marginLeft: "-20px",
    objectFit: "fill",
    [theme.breakpoints.down("lg")]:{
        display: "none",
    }
}))

function ContactOverView() {
    return (
        <ContactOverViewContainer>
            <ContactOverViewLeftContainer>
                <ContactOverViewTitleContainer>
                    <ContactOverViewTitle>CONTACTEZ-NOUS</ContactOverViewTitle>
                </ContactOverViewTitleContainer>
                <ContactOverViewButtonContainer>
                    <ContactOverViewOneButton icone={'images/contactPhoneIcon.png'} text={"+228 93672828 / 98632828"} link="tel:+22893672828"/>
                    <ContactOverViewOneButton icone={'images/contactMailIcon.png'} text={"freemarketgroup.tg@gmail.com"} link="mailto:freemarketgroup.tg@gmail.com"/>
                </ContactOverViewButtonContainer>
                <Grid container>
                    <Grid item xs display="flex" justifyContent="center"><SocialMediaButton children={<WhatsApp fontSize="large"/>} link="https://wa.me/+22893672828"/></Grid>
                    <Grid item xs display="flex" justifyContent="center"><SocialMediaButton children={<LinkedIn fontSize="large"/>} link="https://www.linkedin.com/company/freemarket-groupe/?viewAsMember=true"/></Grid>
                    <Grid item xs display="flex" justifyContent="center"><SocialMediaButton children={<Facebook fontSize="large"/>} link="https://www.facebook.com/profile.php?id=100089742433559&mibextid=ZbWKwL"/></Grid>
                </Grid>
            </ContactOverViewLeftContainer>
            <ContactOverViewImage src="images/CONTACT ACCUEIL.png"/>
        </ContactOverViewContainer>
    );
}

export default ContactOverView;