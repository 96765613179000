import { styled, SxProps } from "@mui/material";

const ClientsImageContainerImage = styled("img")(({theme}) => ({
    width: "140px",
    height: "100px",
    filter: 'grayscale(100%)',
    "&:hover": {
        filter: 'grayscale(0%)',
    },
    // [theme.breakpoints.down('lg')]: {
    //     display: "none"
    // }
}));
interface Clients {
    img: string,
    sx?: SxProps
}
function OurClientsImages({img, sx = {}} : Clients) {
    return (
        <ClientsImageContainerImage src={img} sx={{...sx}}/>
    );
}

export default OurClientsImages;