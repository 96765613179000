import { styled, SxProps } from "@mui/material";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const HeaderMenuItemContainer = styled("div")(() => ({
    marginRight: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}));
const HeaderMenuItemLink = styled(Link)(() => ({
    textDecoration: "none",
    color: "rgb(0,0,0)",
    "&:hover": {
        color:  "#F27405"
    }
}));
const HeaderMenuItemIndicator = styled("div")(() => ({
    width: "10px",
    height: "10px",
    borderRadius: "10px",
    backgroundColor: "#F27405"
}));
interface Pops {
    title: string,
    path: string,
    onClick?: Function,
    sx?: SxProps
}

function HeaderMenuItem({title, path, onClick = () => {}, sx = {}}: Pops) {
    let resolved = useResolvedPath(path);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <HeaderMenuItemContainer onClick={() => onClick()} sx={{...sx}}>
            <HeaderMenuItemLink to={path} sx={{color: match ? "#F27405" : ""}}>{title}</HeaderMenuItemLink>
            <HeaderMenuItemIndicator style={{backgroundColor: match ? "#F27405" : "rgba(0,0,0,0)"}}/>
        </HeaderMenuItemContainer>
    );
}

export default HeaderMenuItem;