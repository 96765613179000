import { styled } from '@mui/material';

const OurResultsImagesContainer= styled("img")(()=>({
    
}));

interface Props{
    image: string;
}

function OurResultsImages({image}: Props) {
    return (
        <OurResultsImagesContainer src={image}/>
    );
}


export default OurResultsImages;